<template>
  <div>
    <div class="heard-content">
      <div class="heard-content__background">
        <label class="heard-content__label">{{ wecome_text }}</label>
      </div>
    </div>
    <div class="basic_container">
      <div style="width: 400px; flex: 1;">
        <el-row type="flex" style="display: flex;" :gutter="40">
          <el-col :span="3">
            <img
                style="width: 230px;height: 130px"
                :src="menu.logoImage"
            />
          </el-col>
          <el-col :span="15">
            <el-row style="margin-top: 20px;margin-left: 150px">
              <el-col>
                <label
                    class="heard-content__label_name">
                  {{ menu.compcty_name }}
                </label>
              </el-col>
              <el-col>
                <label style="display: inline-block;margin-left: 40px">{{ menu.compcty_name_en }}</label>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </div>
      <div class="heard-menu">
        <ul class="heard-menu__ui">
          <li v-for="(v,i) of menu.menu_text" :key="i"
              class="class_menu_text heard-menu__li" @click="do_link(v.path)">
            <i :class="v.icon"></i>
            {{ v.name }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {httpAction} from "@/api/manage";

export default {
  name: "Heard",
  data() {
    return {
      wecome_text: "成都市锦荣纤维制造科技公司欢迎您",
      menu: {
        logoImage: require("@/assets/com_logo.jpg"),
        compcty_name: "四川锦荣科技",
        compcty_name_en: "SICHUANJINRRONGKEJI",
        menu_text: [
          // {name: "网站首页", path: "/"},
          // {name: "关于锦荣", path: "/aboutus"},
          // {name: "产品中心", path: "/product"},
          // // {name: "新闻中心", path: "/news_center"},
          // // {name: "新品发布", path: "/news_sub"},
          // {name: "联系我们", path: "/link_us"}
        ]
      },
      urls: {
        list: "/dictItemManagement/list",
      }
    }
  },
  created() {
    this.initView()
  },
  methods: {
    do_link(path) {
      this.$router.push(path)
    },
    async initView() {
      let params = {
        body: {
          dictCode: "menu",
          status: 1,
        },
        header: {
          sortList: [{orderField: "sortNo", orderType: "asc"}]
        }
      }
      const res = await httpAction(this.urls.list, params)
      if (res.code !== 0) {
        return;
      }
      let menus = []
      if (!res.data)
        return
      res.data.forEach(data => {
        const {itemText: name, itemValue: path, description: icon} = data
        menus.push({name, path, icon})
      })
      this.$set(this.menu, "menu_text", menus);
    }
  }

}
</script>

<style scoped>
.basic_container {
  width: 1080px;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  margin: 10px auto;
}

.class_menu_text:hover {
  border-top: deepskyblue 3px solid;
}

.heard-content {
  background-color: #d4d3d3;
  height: 40px;
}

.heard-content__background {
  width: 1300px;
  display: flex;
  margin: 0 auto;
  position: relative;
  line-height: 40px;
  font-size: 12px;
}

.heard-content__label_name {
  display: inline-block;
  width: 280px;
  font-size: 1.3rem;
  font-weight: bold;
  font-family: Regular;
  line-height: 50px;
  letter-spacing: 5px;
}

.heard-content__label {
  display: inline-block;
  position: absolute;
  left: 0;
  font-weight: bold;
}

.heard-menu {
  height: 40px;
  width: 630px;
  flex: 1;
  position: relative;
}

.heard-menu__ui {
  display: flex;
  list-style: none;
  line-height: 100px;
  text-align: center;
  font-size: 1.2rem;
  font-family: Regular;
  font-weight: bold;
}

.heard-menu__li {
  flex: 1;
  margin-right: 20px;
  cursor: pointer
}


</style>
