<template>
<div class="basic_container_noflex" style="margin-top: 50px;height: 100px">
  <h4 style="font-size: 14px;color: #b9b6b6">
    版权所有：成都市锦荣源纤维科技有限公司  网站建设 《中华人民共和国电信与信息服务业务经营许可证》蜀ICP备2021009798号
  </h4>
</div>
</template>

<script>
export default {
  name: "TopMessage"
}
</script>

<style scoped>
.basic_container_noflex {
  width: 1300px;
  position: relative;
  margin: 10px auto;
}
</style>
