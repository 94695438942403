<template>
  <div>
    <div style="background-color: #e4e4e4;">
      <div class="basic_container" style="display: flex;justify-content: center;">
        <div v-for="(item,i) in items" style="width: 31%;margin-left: 20px">
          <el-row :gutter="20">
            <el-col :span="4">
              <div style="margin-top: 20px;">
                <el-image :src=item.icon></el-image>
              </div>
            </el-col>
            <el-col :span="20">
              <div style="text-align: left;">
                <div>
                  <p style="font-size: 15px">{{ item.text }}</p>
                  <p style="font-size:15px">{{ item.text_Context }}</p>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {httpAction} from "@/api/manage";

export default {
  name: "TopTag",
  data() {
    return {
      items: [
        {
          text: "ADDRESS",
          text_Context: "四川省成都市彭州市濛阳县物流大道南段",
          icon: require("@/assets/address_icon.png"),
          border_left: true,
        }, {
          text: "CALL US",
          text_Context: "电话：13438867270",
          icon: require("@/assets/phone_icon.png"),
          border_left: true,
        },
        {
          text: "EMAIL",
          text_Context: "924736584@qq.com",
          icon: require("@/assets/email_icon.png"),
          border_left: false,
        }
      ],
      urls: {
        companyInfo: "/companyManage/getCompanyInfo"
      }
    }
  },
  mounted() {
    this.getCompanyInfo()
  },
  methods: {
    getCompanyInfo() {
      httpAction(this.urls.companyInfo, {body: {}}).then(resp => {
        if (resp.code == 0) {
          const {companyAddress, companyPhone, companyEmail} = resp.data
          this.items = [
            {
              text: "ADDRESS",
              text_Context: companyAddress,
              icon: require("@/assets/address_icon.png"),
              border_left: true,
            }, {
              text: "CALL US",
              text_Context: "电话：" + companyPhone,
              icon: require("@/assets/phone_icon.png"),
              border_left: true,
            },
            {
              text: "EMAIL",
              text_Context: companyEmail,
              icon: require("@/assets/email_icon.png"),
              border_left: false,
            }
          ]
        }
      });
    }
  }

}
</script>

<style scoped>
.basic_container {
  width: 1080px;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  margin: 10px auto;
}

.basic_container_noflex {
  width: 1080px;
  position: relative;
  margin: 10px auto;
}
</style>
